import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import SEO from "../../components/seo"
import Layout from '../../containers/layout/layout'
import Header from '../../containers/layout/header/header-one'
import PageHeader from '../../containers/service-header/support-maintenance/page-header'
import Footer from '../../containers/layout/footer/footer-one'
import { Container, Box, Row, Col } from '../../components/ui/wrapper'
import Heading from '../../components/ui/heading'
import BoxIcon from '../../components/box-icon/layout-three'
import CTA from '../../containers/global/cta-area/section-four'

import { IntroArea, FeatureArea, FaqArea, VideoBoxWrap } from '../../templates/service-template/service-template.style'

const ServicesSupportMaintenance = ({ location, introTextStyles: { leftHeading, rightHeading }, featureStyels: { featureTitle } }) => {
    const ServicesSupportMaintenanceData = useStaticQuery(graphql`
        query ServicesSupportMaintenanceQuery {
            services(title: {eq: "Support & Maintenance"}) {
                introText{
                    heading
                    text
                }
                faq{
                    id
                    title
                    desc
                }
                video{
                    preview_image
                    video_link
                }
                features {
                    title
                    items {
                        id
                        title
                        icon
                        desc
                    }
                }
            }
        } 
    `);

    const pageData = ServicesSupportMaintenanceData.services;

    return (
        <Layout location={location}>
            <SEO title={pageData.title} />
            <Header />
            <main className="site-wrapper-reveal">
                <PageHeader />
                {pageData.introText && (
                    <IntroArea>
                        <Container>
                            <Row>
                                <Col lg={4}>
                                    <Heading {...leftHeading}>We Help Turn Your <span> Ideas Into Reality</span></Heading>
                                </Col>
                                {pageData.introText && (
                                    <Col lg={{ span: 7, offset: 1 }}>
                                        <Box>
                                            {pageData.introText.heading && <Heading {...rightHeading}>{parse(pageData.introText.heading)}</Heading>}
                                            {/* {pageData.introText.text && <Text {...rightText}>{parse(pageData.introText.text)}</Text>} */}
                                        </Box>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </IntroArea>
                )}
                {(pageData.faq || pageData.preview) && (
                    <FaqArea>
                        <Container>
                            <Row>
                                {pageData.video && (
                                    <Col lg={12}>
                                        <VideoBoxWrap>
                                            {pageData.video.preview_image && <img src={pageData.video.preview_image} alt="Support and Maintenance"/>}
                                        </VideoBoxWrap>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </FaqArea>
                )}
                {pageData.features && (
                    <FeatureArea>
                        <Container>
                            {pageData.features.title && (
                                <Row>
                                    <Col col="12">
                                        <Heading {...featureTitle}>{pageData.features.title}</Heading>
                                    </Col>
                                </Row>
                            )}
                            {pageData.features.items && (
                                <Row>
                                    {pageData.features.items.map(feature => (
                                        <Col lg={6} key={feature.id}>
                                            <BoxIcon
                                                title={feature.title}
                                                icon={feature.icon}
                                                desc={feature.desc}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </Container>
                    </FeatureArea>
                )}
                <CTA />
            </main>
            <Footer />
        </Layout>
    )
}

ServicesSupportMaintenance.propTypes = {
    introTextStyles: PropTypes.object,
    titleStyle: PropTypes.object,
    taglineStyle: PropTypes.object,
    featureStyels: PropTypes.object
}

ServicesSupportMaintenance.defaultProps = {
    titleStyle: {
        as: 'h1',
        color: '#fff',
        mb: '15px'
    },
    introTextStyles: {
        leftHeading: {
            as: 'h4',
            mt: '25px',
            fontSize: '38px',
            child: {
                color: 'primary'
            },
            responsive: {
                large: {
                    fontSize: '30px'
                },
                medium: {
                    mt: 0,
                    mb: '20px',
                    fontSize: '28px'
                },
                small: {
                    fontSize: '24px'
                }
            }
        },
        rightHeading: {
            as: 'h5',
            position: 'relative',
            pl: '34px',
            lineheight: 1.67,
            fontweight: 800,
            layout: 'quote',
            child: {
                color: 'primary'
            },
        },
        rightText: {
            mt: '15px',
            fontSize: '18px',
            ml: '34px',
            color: '#696969'
        }
    },
    featureStyels: {
        featureTitle: {
            as: 'h3',
            textalign: 'center',
            mb: '20px'
        }
    }
}

export default ServicesSupportMaintenance;