import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import {
    BannerArea,
    BannerTextWrap
} from '../../../../templates/service-template/service-template.style'

const SupportMaintenancePageHeader = ({ titleStyle }) => {
    const SupportMaintenancePageHeaderData = useStaticQuery(graphql`
        query SupportMaintenancePageHeaderQuery {
            services(title: {eq: "Support & Maintenance"}) {
                title
                banner_image
            }
        } 
    `);

    const {title, banner_image } = SupportMaintenancePageHeaderData.services
    
    return (
        <BannerArea src={banner_image}>
            <Container>
                <Row justify="center">
                    <Col lg={10}>
                        <BannerTextWrap>
                            {/* {pageData.logo && <Img fixed={pageData.logo.childImageSharp.fixed} alt={pageData.title} />} */}
                            {title && <Heading {...titleStyle}>{title}</Heading>}
                        </BannerTextWrap>
                    </Col>
                </Row>
            </Container>
        </BannerArea>
    )
}

SupportMaintenancePageHeader.propTypes = {
    titleStyle: PropTypes.object
}

SupportMaintenancePageHeader.defaultProps = {
    titleStyle: {
        as: 'h1',
        color: '#fff',
        mb: '15px'
    }
}

export default SupportMaintenancePageHeader;